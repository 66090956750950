.btn {
    background-color: $color-3;
    border: 1px solid $color-2;
    border-radius: 6px;
    width: 175px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 600;
    font-size: $font-size-18;
    color: $color-1;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-2;
        color: $color-1;
    }
}
