// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    width: 235px;
    height: 80px;
    border-radius: 6px;
    background: $color-3;
    margin-top: 50px;
    transition: 0.3s;
    h5 {
        font-weight: 600;
        font-size: $font-size-18;
        text-transform: uppercase;
        color: $color-1;
        letter-spacing: 1.8px;
    }
    &:hover {
        background: $color-2;
        transition: 0.3s;
    }
    @media screen and (max-width: $size-xs-max) {
        width: 175px;
        height: 70px;
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-loi25 {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 101;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 70px;
        left: initial;
        right: 10px;
    }
}

#section-image-text {
    padding: 75px 0;
    margin: 35px 0 0 0;
    position: relative;
    .image-side {
        width: 55%;
        margin-right: 50px;
    }
    .text-side {
        width: 50%;
        padding-right: 13%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #2D2D2D;
            line-height: 1.2;
            text-transform: initial;
        }
        p {
            padding: 25px 0;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-26;
            color: #2D2D2D;
            line-height: 1.3;
            font-style: italic;
        }
    }
    &::before {
        content: '';
        width: 71%;
        height: 100%;
        background: #0808080B;
        position: absolute;
        right: 0;
        z-index: -1;
    }
    @media screen and (max-width: 1640px) {
        .text-side {
            padding-right: 5%;
        }
        @media screen and (max-width: 1215px) {
            flex-direction: column;
            padding: 75px 5%;
            .image-side {
                width: 75%;
                margin: 0 0 50px 0;
            }
            .text-side {
                padding-right: 0;
                width: 100%;
            }
            &::before {
                width: 100%;
                bottom: 0;
                height: 65%;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side {
                    width: 100%;
                }  
                &::before {
                    height: 65%;
                }   
                @media screen and (max-width: $size-xs-max) {
                    margin: 0;
                    &::before {
                        height: 67%;
                    } 
                }   
            }
        } 
    }
}

#section-project {
    padding: 75px 5%;
    .section-top {
        padding-bottom: 75px;
        .section-stats {
            width: 33%;
            .left {
                padding-right: 10px;
                h2 {
                    font-weight: bold;
                    font-size: $font-size-40;
                    color: $color-3;
                }
            }
            .right {
                h4 {
                    font-weight: 600;
                    font-size: $font-size-20;
                    color: #2B2B2B;
                    text-transform: uppercase;
                }
            }
        }
        .text {
            width: 48%;
            h5 {
                font-weight: bold;
                font-size: $font-size-20;
                color: $color-3;
            }
        }
        .section-text {
            width: 70%;
            h3 {
                font-weight: normal;
                font-size: $font-size-20;
                color: $color-3;
                text-transform: initial;
                line-height: 1.3;
            }
        }
    }
    .section-bottom {
        .service {
            .img-responsive {
                margin: auto;
            }
            h5 {
                font-weight: 600;
                font-size: $font-size-20;
                color: #2B2B2B;
                text-align: center;
                padding-top: 20px;
            }
        }
    }
    @media screen and (max-width: 1620px) {
        .section-top .section-text {
            width: 60%;
        }
        @media screen and (max-width: 1480px) {
            .section-top {
                .section-text {
                    width: 50%;
                }
            }
            @media screen and (max-width: 1400px) {
                .section-top {
                    flex-wrap: wrap;
                    .section-stats {
                        width: 50%;
                        justify-content: center;
                    }
                    .text {
                        width: 50%;
                    }
                    .section-text {
                        width: 100%;
                        padding-top: 50px;
                        text-align: center;
                        h3 {
                            font-size: 1.3rem;
                        }
                    }
                }
                @media screen and (max-width: 1250px) {
                    .section-bottom {
                        flex-wrap: wrap;
                        justify-content: center;
                        .service {
                            width: 25%;
                            padding: 25px;
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .section-top {
                            .text h5 br {
                                display: none;
                            }
                        }
                        .section-bottom {
                            .service {
                               h5 br {
                                    display: none;
                               }
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            padding: 50px 5% 35px 5%;
                            .section-top {
                                .section-stats {
                                    width: 100%;
                                    padding-bottom: 25px;
                                }
                                .text {
                                    width: 100%;
                                    text-align: center;
                                    padding: 25px 10px;
                                }
                                .section-text {
                                    padding-top: 10px;
                                }
                            }
                            .section-bottom {
                                .service {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#border {
    background: $color-3;
    height: 2px;
    margin: 10px 5%;
}

#section-tarifs {
    padding: 75px 5% 50px 5%;
    .title {
        text-align: center;
        padding-bottom: 75px;
        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: #100000;
            padding-bottom: 15px;
        }
        h5 {
            font-weight: 600;
            font-size: $font-size-20;
            color: #202124;
            text-transform: uppercase;
        }
    }
    .section-blocs {
        .bloc {
            width: 33.3333%;
            .section-title {
                background: #101000;
                padding: 25px 15px 25px 25px;
                margin-bottom: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                h2 {
                    font-weight: bold;
                    font-size: $font-size-30;
                    color: $color-1;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;
                }
            }
            .section-text {
                background: #E4E4E4;
                padding: 50px 30px 75px 30px;
                height: 515px;
                h4 {
                    font-weight: 600;
                    font-size: $font-size-18;
                    color: $color-3;
                    @media screen and (max-width: 1747px) {
                        &.last {
                            display: none;
                        }
                    }
                }
                a.btn-link.long {
                    width: 320px;
                }
            }
        }
    }
    @media screen and (max-width: 1730px) {
        .section-blocs .bloc .section-title h2 {
            font-size: 1.3rem;
        }
        @media screen and (max-width: 1550px) {
            .section-blocs {
                .bloc- {
                    .section-title h2 {
                        font-size: 1.15rem;
                    }
                }
            }
            @media screen and (max-width: 1500px) {
                .section-blocs {
                    flex-wrap: wrap;
                    .bloc {
                        width: 45%;
                        padding-bottom: 1.5rem;
                        &.big {
                            .section-text {
                                height: 650px;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1024px) {
                    .section-blocs {
                        .bloc {
                            width: 50%;
                            .section-text a.btn-link.long {
                                width: 260px;
                            }
                        }
                    }
                    @media screen and (max-width: $size-sm-max) {
                        .section-blocs {
                            .bloc {
                                width: 100%;
                                .section-title {
                                    h2 {
                                        font-size: 1.5rem;
                                    }
                                }
                                .section-text {
                                    height: initial !important;
                                }
                                &.px3 {
                                    padding: 0 0 1.5rem 0;
                                }
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .section-blocs {
                                .bloc {
                                    .section-text {
                                        padding: 50px 20px 75px 20px;
                                        a.btn-link.long {
                                            width: 210px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-a-savoir {
    padding: 75px 5% 25px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-50;
        color: #100000;
        text-align: center;
        padding-bottom: 50px;
    }
    .section-text {
        .left-side {
            margin-right: 50px;
            h4 {
                font-weight: normal;
                font-size: $font-size-20;
                color: #202124;
                text-transform: uppercase;
            }
        }
    }
    a.btn-link {
        margin: 100px auto 0 auto;
    }
    @media screen and (max-width: 1250px) {
        .section-text {
            flex-direction: column;
            .left-side {
                margin: 0 0 35px 0;
            }
        }
        a.btn-link {
            margin: 70px auto 0 auto;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 50px 5% 25px 5%;
        }
    }
}

#section-text-image {
    padding: 50px 5% 50px 0;
    margin: 100px 0 75px 0;
    position: relative;
    .image-side {
        width: 60%;
        margin-left: 50px;
    }
    .text-side {
        width: 40%;
        padding: 0 5%;
        h4 {
            font-weight: 600;
            font-size: $font-size-20;
            color: #202124;
            line-height: 1.3;
        }
    }
    &::before {
        content: '';
        width: 58%;
        height: 100%;
        background: #E4E4E4;
        position: absolute;
        left: 0;
        z-index: -1;
    }
    @media screen and (max-width: 1150px) {
        flex-direction: column-reverse;
        padding: 75px 5%;
        .image-side {
            width: 75%;
            margin: 0 0 15px 0;
        }
        .text-side {
            padding: 25px 0;
            width: 100%;
        }
        &::before {
            width: 100%;
            height: 60%;
            bottom: 0;
        }
        @media screen and (max-width: $size-sm-max) {
            padding: 25px 5% 75px 5%;
            .image-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 25px 5% 50px 5%;
                &::before {
                    height: 68%;
                }
            }
        }
    }
}

#section-images {
    padding: 75px 5%;
    .left-side {
        width: 50%;
        margin-right: 15px;
    }
    .right-side {
        width: 50%;
    }
    .text {
        padding: 20px 15px 0 15px;
        h3 {
            font-weight: 600;
            font-size: $font-size-20;
            color: #202124;
            line-height: 1.3;
        }
    }
    @media screen and (max-width: 1150px) {
        flex-direction: column;
        .left-side {
            width: 75%;
            margin: 0 auto 50px auto;
        }
        .right-side {
            width: 75%;
            margin: auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side, .right-side {
                width: 100%;
            }
            .text {
                padding: 25px 15px 0 15px;
                h3.last {
                    display: none;
                }
            }
            @media screen and (max-width: $size-xs-max) {
                .text h3 {
                    br {
                        display: none;
                    }
                }
                a.btn-link {
                    margin-top: 35px;
                }
            }
        }
    }
}

/*******************************************************************************
* RÉSIDENTIEL
*******************************************************************************/

#section-intro-up.reverse {
    padding: 0 5% 75px 5%;
    margin-top: -175px;
    position: relative;
    .left-side {
        width: 55%;
        background: url("../images/residentiel_section01_img01.jpg") no-repeat;
        background-size: cover;
        background-position: right;
        margin-right: 15px;
    }
    .right-side {
        width: 45%;
        margin-right: 15px;
        background: #100000;
        padding: 65px 45px 35px 45px;
        h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: #E4E4E4;
            padding-bottom: 25px;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-20;
            color: #FFFF00;
            text-transform: uppercase;
            padding-bottom: 35px;
        }
        p {
            color: #E4E4E4;
        }
        a.btn-link {
            border: 1px solid #016538;
        }
    }
    @media screen and (max-width: 1480px) {
        .right-side .section-title p br {
            display: none;
        }
        @media screen and (max-width: 1170px) {
            flex-direction: column;
            margin-top: -110px;
            .right-side {
                width: 100%;
            }
            .left-side {
                display: none;
            }
            @media screen and (max-width: $size-sm-max) {
                margin-top: -75px;
                @media screen and (max-width: $size-xs-max) {
                    margin-top: -75px;
                    .right-side {
                        padding: 60px 25px 35px 25px;
                    }
                }
            }
        }
    }
}

#section-img-img {
    padding: 25px 5% 0 5%;
    .left-side {
        width: 50%;
        margin-right: 25px;
        .section-list {
            h4 {
                font-weight: 600;
                font-size: $font-size-18;
                color: #202124;
                text-transform: uppercase;
            }
            .text {
                padding-top: 0;
            }
            .left {
                width: 47%;
                margin-right: 25px;
            }
        }
    }
    .right-side {
        width: 50%;
        .section-list {
            h4 {
                font-weight: 600;
                font-size: $font-size-18;
                color: #202124;
                text-transform: uppercase;
                text-decoration: underline;
            }
            .left {
                width: 50%;
                margin-right: 25px;
            }
        }
    }
    .section-text {
        padding-top: 20px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 15px;
        }
        p {
            width: 90%;
        }
    }
    .text {
        padding-top: 20px;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 20px;
        }
    }
    @media screen and (max-width: 1410px) {
        .left-side .section-list .left {
            width: 60%;
        }
        @media screen and (max-width: 1332px) {
            .section-text h3 br {
                display: none;
            }
            @media screen and (max-width: 1150px) {
                &.last {
                    flex-direction: column;
                    .left-side {
                        width: 80%;
                        margin: 0 auto 50px auto;
                    }
                    .right-side {
                        width: 80%;
                        margin: auto;
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin: 0 0 35px 0;
                    }
                    .right-side {
                        width: 100%;
                    }
                    .section-text {
                        p {
                            width: 100%;
                        }
                    }
                    &.last {
                        .left-side, .right-side {
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .left-side {
                            .section-list {
                                flex-direction: column;
                                .left {
                                    width: 100%;
                                    margin: 0 0 15px 0;
                                }
                            }
                        }
                        .right-side .section-list h4 {
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
    }
}

#section-images.residentiel {
    a.btn-link.long {
        width: 485px;
    }
    a.btn-link.medium {
        width: 425px;
    }
    @media screen and (max-width: 1024px) {
        a.btn-link.long {
            width: 415px;
            margin-top: 25px;
        }
        a.btn-link.medium {
            width: 350px;
        }
        @media screen and (max-width: $size-xs-max) {
            a.btn-link.long {
                width: 325px;
            }
            a.btn-link.medium {
                width: 285px;
            }
            @media screen and (max-width: 375px) {
                a.btn-link.long {
                    width: 275px;
                    text-align: center;
                }
            }
        }
    }
}

/*******************************************************************************
* COMMERCIAL / INDUSTRIEL
*******************************************************************************/

#section-intro-text {
    background: #100000;
    margin: -150px 5% 100px 5%;
    position: relative;
    padding: 75px 35px;
    .left-side {
        width: 44%;
        margin-right: 10%;
        h3 {
            font-weight: bold;
            font-size: $font-size-50;
            color: #E4E4E4;
            padding-bottom: 50px;
        }
        h4 {
            font-weight: 600;
            font-size: $font-size-20;
            color: #FFFF00;
            text-transform: uppercase;
            line-height: 1.3;
        }
        a.btn-link {
            border: 1px solid #016538;
        }
    }
    .right-side {
        width: 45%;
        p {
            color: #E4E4E4;
        }
    }
    @media screen and (max-width: 1520px) {
        .left-side {
            h3 {
                font-size: 2.2rem;
            }
        }
        @media screen and (max-width: 1350px) {
            flex-direction: column;
            .left-side {
                width: 100%;
                margin: 0 0 35px 0;
                h3 {
                    font-size: 2.3rem;
                    padding-bottom: 20px;
                }
            }
            .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                padding: 75px 20px;
                margin: -110px 5% 100px 5%;
                .left-side h3 {
                    font-size: 1.75rem;
                }
                @media screen and (max-width: 375px) {
                    .left-side h3 {
                        font-size: 1.6rem;
                    }
                    @media screen and (max-width: 360px) {
                        .left-side h3 {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}

#section-text-background {
    padding: 75px 5% 50px 5%;
    .left-side {
        background: #E4E4E465;
        width: 45%;
        margin-right: 15px;
        padding: 0 25px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 50px;
        }
        a.btn-link {
            width: 465px;
            margin-top: 75px;
        }
    }
    .right-side {
        width: 55%;
        background: url("../images/commercial_section04_img01.jpg") no-repeat;
        background-size: cover;
    }
    @media screen and (max-width: 1600px) {
        .left-side {
            h3 {
                font-size: 1.35rem;
                padding-bottom: 35px;
            }
            a.btn-link {
                width: 450px;
                margin-top: 50px;
            }
        }
        @media screen and (max-width: 1450px) {
            .left-side {
                h3 {
                    font-size: 1.3rem;
                    padding-bottom: 20px;
                    br {
                        display: none;
                    }
                }
                a.btn-link {
                    width: 435px;
                    margin-top: 30px;
                }
            }
            @media screen and (max-width: 1350px) {
                flex-direction: column-reverse;
                .right-side {
                    width: 100%;
                    margin: auto;
                }
                .left-side {
                    width: 100%;
                    padding: 75px 25px;
                    margin-right: 0;
                }
                @media screen and (max-width: $size-xs-max) {
                    .left-side {
                        padding: 50px 20px;
                        a.btn-link {
                            width: 100%;
                            text-align: center;
                        }
                    }
                    .right-side {
                        background: url("../images/commercial_section04_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                }
            }
        }
    }
}

#section-text-img {
    padding: 50px 5%;
    .left-side {
        width: 50%;
    }
    .right-side {
        background: #E4E4E465;
        width: 50%;
        padding: 0 35px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #100000;
            padding-bottom: 50px;
        }
        .section-list {
            h4 {
                font-weight: 600;
                font-size: $font-size-18;
                color: #202124;
                text-transform: uppercase;
            }
            .left {
                width: 45%;
                margin-right: 25px;
            }
        }
    }
    @media screen and (max-width: 1470px) {
        .right-side .section-list .left {
            width: 55%;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                width: 75%;
                margin: auto;
            }
            .right-side {
                width: 75%;
                margin: auto;
                padding: 50px 35px;
            }
            @media screen and (max-width: $size-sm-max) {
                .left-side, .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .right-side {
                        padding: 50px 20px;
                        .section-list {
                            flex-direction: column;
                            .left {
                                width: 100%;
                                margin: 0 0 15px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 0 5% 100px 5%;
    background: #E4E4E465;
    .form-info.flex {
        position: relative;
        margin-top: -250px;
    }
    .section-form {
        width: 65%;
        margin-right: 15px;
        background: $color-white;
        padding: 65px 100px 50px 100px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #2D2D2D;
            padding-bottom: 15px;
        }
        p {
            padding-bottom: 25px;
        }
        .form-group {
            margin-bottom: -15px;
        }
    }
    .section-info {
        width: 35%;
        padding: 65px 45px 35px 45px;
        background: #100000;
        h2 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #E4E4E4;
            letter-spacing: 1.2px;
            text-transform: uppercase;
        }
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #E4E4E4;
            letter-spacing: 1.2px;
            padding-bottom: 15px;
        }
        .section-tel {
            padding-top: 50px;
            h4, a {
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
            }
        }
        .section-address {
            padding: 50px 0;
            h5 {
                font-weight: 600;
                font-size: $font-size-20;
                color: #E4E4E4;
            }
        }
        .jours-heures {
            .jour {
                width: 43%;
            }
            h4, h5 {    
                font-weight: normal;
                font-size: $font-size-20;
                color: #E4E4E4;
                line-height: 1.8;
            }
        }
    }
    @media screen and (max-width: $size-md-max) {
        .form-info {
            flex-direction: column-reverse;
            margin-top: -150px !important;
            .section-info {
                width: 70%;
                margin: auto;
                padding: 65px 90px 50px 90px;
            }
            .section-form {
                width: 85%;
                margin: 35px auto 0 auto;
            }
        }
        @media screen and (max-width: 1024px) {
            .form-info {
                .section-info {
                    width: 75%;
                    padding: 65px 45px;
                    .section-tel {
                        padding-top: 70px;
                    }
                    .section-address {
                        padding: 35px 0;
                    }
                }
                .section-form {
                    width: 100%;
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .form-info {
                    margin-top: -95px !important;
                    .section-info {
                        width: 100%;
                        .jours-heures .jour {
                            width: 32%;
                        }
                    }
                    .section-form {
                        padding: 65px 50px 50px 50px;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .form-info {
                        margin-top: -130px !important;
                        .section-info {
                            width: 100%;
                            padding: 50px 25px;
                            .jours-heures .jour {
                                width: 32%;
                            }
                            h2 {
                                text-align: center;
                            }
                        }
                        .section-form {
                            padding: 50px 25px;
                            .form-group.col-xs-12.col-sm-6.pl0 {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-map {
    padding: 75px 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-40;
        color: $color-3;
        padding-bottom: 50px;
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 5px;
        color: $color-1;
    }
    p a {
        color: $color-3;
    }
}








/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
