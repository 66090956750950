.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 45%;
        left: 5%;
        //transform: translateX(-50%);
        z-index: $z-index-slide-caption;

        h2 {
          font-weight: bold;
          font-size: $font-size-70;
          color: $color-white;
        }
        
        @media screen and (max-width: 1600px) {
          top: 42%;
          h2 {
              font-size: 3.2rem;
          }
          @media screen and (max-width: 1450px) {
            top: 40%;
            h2 {
                font-size: 2.8rem;
            }
            @media screen and (max-width: $size-md-max) {
                top: 35%;
                h2 {
                    font-size: 2.5rem;
                }
                @media screen and (max-width: 1024px) {
                    h2 {
                        font-size: 2.2rem;
                    }
                    a.btn-link {
                        margin-top: 35px;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        top: 26%;
                        h2 {
                            font-size: 2rem;
                        }
                        a.btn-link {
                            margin-top: 25px;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            top: 36%;
                            h2 {
                                font-size: 2.1rem;
                            }
                            @media screen and (max-width: 375px) {
                                h2 {
                                    font-size: 1.8rem;
                                }
                                @media screen and (max-width: 360px) {
                                    top: 33%;
                                }
                            }
                        }
                    }
                }
            }
          }
        }
    }

    .section-btn {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
        margin-right: 5%;
        a.link-slideshow {
            background: $color-1;
            width: 185px;
            height: 80px;
            h4 {
                font-weight: bold;
                font-size: $font-size-18;
                color: #100000;
                text-transform: uppercase;
            }
        }
        @media screen and (max-width: 1024px) {
          position: initial;
          margin: 0;
          a.link-slideshow {
              width: 33.3333%;
          }
          @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            a.link-slideshow {
                width: 100%;
                &.mx2 {
                    margin: 0.5rem 0;
                }
            }
          }
        }
    }

    .slideshow {
        width: 100%;
        overflow: hidden;
        position: relative;
        .placeholder {
            width: 100vw;
            height: auto;
            display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                //height: calc(100vw / 480 * 600);
            }
        }
        &.mobile {
            display: none;
        }
        .slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
            img {
               width: 100vw!important;
               height: auto;
               display: block;
               position: relative;
            }
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
            }
            &.active {
                right: 0;
                left: 0;
                z-index: 10;
                opacity: 1;
                transition: 1.5s;
            }
          }
          @media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
          }
      }
}
